<template>
  <div :class="!isApp ? 'appTop' : ''">
    <!-- 组合修炼 -->
    <div class="main">
      <div class="" v-for="(item, index) in myComList" :key="index">
        <div class="items">
          <div class="title">
            <div class="name">组合修炼{{ index + 1 }}</div>
            <div class="icon">
              <van-icon
                @click="chooseItem(index)"
                :name="item.result_item.checked ? coll_choose : coll_no_choose"
                class=""
              /><span>选择</span>
            </div>
          </div>
          <div class="card-box">
            <div class="card-item">
              <div class="card">
                <img :src="item.result_item.image_icon" alt="" />
                <img class="levelIcon" :src="levelsChange(item.result_item.levels)" />
              </div>

              <div
                class="meng t-c"
                v-if="item.result_item.status == 1 && diffTime(item.result_item.end_time) < 0"
              >
                <div class="point">{{ item.result_item.points }} <span>积分</span></div>
                <div class="btn" @click="getPoint(item.result_item.user_collection_id)">领取</div>
              </div>
              <div class="tips">
                <span v-if="item.result_item.status == 1 && diffTime(item.result_item.end_time) < 0"
                  >修炼已结束</span
                >
                <div
                  class=""
                  v-if="item.result_item.status == 1 && diffTime(item.result_item.end_time) > 0"
                >
                  <div class="" v-if="diffTime(item.result_item.end_time) > 24 * 60 * 60 * 1000">
                    {{ formatDuring(diffTime(item.result_item.end_time)) }}
                  </div>
                  <div class="countdown" v-else>
                    <van-count-down
                      class=""
                      :time="diffTime(item.result_item.end_time)"
                      @finish="getList"
                    />结束
                  </div>
                </div>
              </div>
              <div class="t-c num">编号：{{ item.result_item.serial_num }}</div>
            </div>

            <div class="card-item">
              <div class="card">
                <img :src="item.equity_item.image_icon" alt="" />
                <img class="levelIcon" :src="levelsChange(item.equity_item.levels)" />
              </div>

              <div class="meng t-c comMeng">
                <img src="../../imgs/learn/fastIcon.png" alt="" />
              </div>
              <div class="tips">
                <span v-if="diffTime(item.equity_item.end_time) < 0">修炼已结束</span>
                <div class="" v-if="diffTime(item.equity_item.end_time) > 0">
                  <div class="" v-if="diffTime(item.equity_item.end_time) > 24 * 60 * 60 * 1000">
                    {{ formatDuring(diffTime(item.equity_item.end_time)) }}
                  </div>
                  <div class="countdown" v-else>
                    <van-count-down
                      class=""
                      :time="diffTime(item.equity_item.end_time)"
                      @finish="getList"
                    />结束
                  </div>
                </div>
              </div>
              <div class="t-c num">编号：{{ item.equity_item.serial_num }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="footer">
      <div class="type3">
        <div class="icon">
          <van-icon
            @click="chooseAllChange"
            :name="chooseAll ? coll_choose : coll_no_choose"
          /><span>全选</span>
        </div>
        <div class="btn" :style="{ opacity: checkedArr.length ? 1 : 0.7 }" @click="showStopMeth">
          终止修炼({{ checkedArr.length }})
        </div>
      </div>
    </div>
    <div v-if="showNull" class="dataNullBox">
      <div>
        <img src="@/imgs/comm/dataNull.png" alt="" srcset="" />
      </div>
      <div class="text">暂无数据 <van-icon @click="knownBack = true" name="question-o" /></div>
      <div class="icons" @click="sendLearn">去派遣 <van-icon name="arrow" /></div>
    </div>

    <div class="" id="invite-award">
      <van-popup class="vantPop" v-model:show="knownBack">
        <div class="popup">
          <div class="content">
            <div class="title">闭关修炼说明</div>
            <div class="nei">
              <p>
                1、本次参与闭关修炼的藏品：《仙武帝尊》「东荒女帝」、「荒古大帝」「叶辰」、「姬凝霜」，《盖世帝尊》赤火灵鸟；
              </p>
              <p>2、闭关修炼期间藏品不可转赠、不可合成，但不影响藏品本身的权益；</p>
              <p>3、闭关修炼期间可随时结束修炼，但当次修炼不会获得积分；</p>
              <p>4、闭关修炼得积分规则</p>
              <img src="https://activity-resource.321mh.com/nft/xiulian/legend_epic.png" alt="" />
            </div>
          </div>
          <div class="btn">
            <div @click.stop="knownBack = false">知道了</div>
          </div>
        </div>
      </van-popup>
    </div>

    <popup
      v-model:show.sync="showstop"
      title="终止修炼"
      :desc="`是否终止正在修炼的${checkedArr.length}个藏品？终止后将不可获得完成修炼的${stopPoint}积分哦~`"
      :btncon="{ cancel: '取消', success: '终止' }"
      @confirm="stopClick"
      @cancel="showstop = false"
    />

    <PageLoading :show="pageLoading" />
  </div>
</template>

<script>
import { reactive, toRefs, onMounted } from 'vue';
import {
  getcomstopregularlist,
  receivescores,
  regularconfig,
  comstopregular
} from '@/service/learn';
import PageLoading from '@/components/loading/PageLoading.vue';
import moment from 'moment';
import { nftUtils } from '@/utils';
import { useRouter } from 'vue-router';
import popup from '@/components/popup/index.vue';

export default {
  components: { popup, PageLoading },
  setup() {
    const router = useRouter();
    const state = reactive({
      myComList: [],
      server_time: 0,
      pageLoading: true,
      showNull: true,
      isApp: nftUtils.isInApp(),
      chooseAll: false,
      knownBack: false,
      checkedArr: [],
      configList: [],
      columns: [],
      days: null,
      showPoint: 0,
      showstop: false,
      stopPoint: 0
    });
    onMounted(async () => {
      getList();
      getBasic();
    });
    // 获取基础配置
    const getBasic = async () => {
      const { data } = await regularconfig();
      state.configList = data;
      // 获取可选择天数配置
      state.configList.map(item => {
        state.columns.push({ id: item.days, name: `修炼${item.days}天` });
      });

      let map = new Map();
      for (let item of state.columns) {
        if (!map.has(item.id)) {
          map.set(item.id, item);
        }
      }
      state.columns = [...map.values()];
      state.days = state.columns[0].id;
    };
    const getList = async () => {
      let comPostMeth = getcomstopregularlist;
      const { data, server_time } = await comPostMeth();
      state.server_time = server_time;
      state.myComList = data;
      state.pageLoading = false;
      state.showNull = true;
      if (state.myComList.length) {
        state.showNull = false;
      }

      state.myComList.forEach(item => {
        item.result_item.checked = false;
      });

      console.log(state.myComList);
    };

    // 计算与当前时间时间差
    const diffTime = time => {
      let mill = moment(moment(time)).diff(moment(state.server_time), 'millisecond');

      return moment(moment(time)).diff(moment(state.server_time), 'millisecond');
    };

    // 倒计时大于1天
    const formatDuring = millisecond => {
      var days = parseInt(millisecond / (1000 * 60 * 60 * 24));
      var hours = parseInt((millisecond % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));

      return days + '天' + hours + '小时' + '结束';
    };

    const levelsChange = level => {
      let src = '';
      //级别 1 普通 icon_label_ordinary，2 稀有 icon_label_rare，3 史诗 icon_label_epic，4 传说 icon_label_legend
      switch (level) {
        case 1:
          src = require('@/imgs/comm/icon_label_ordinary.png');
          break;
        case 2:
          src = require('@/imgs/comm/icon_label_rare.png');
          break;
        case 3:
          src = require('@/imgs/comm/icon_label_epic.png');
          break;
        case 4:
          src = require('@/imgs/comm/icon_label_legend.png');
          break;
        case 6:
          src = require('@/imgs/comm/icon_label_cs.png');
          break;
        case 5:
          src = require('@/imgs/comm/icon_label_sh.png');
          break;
      }
      return src;
    };

    // 积分领取
    const getPoint = async id => {
      let postObj = id == 0 ? null : { id_list: [id] };
      const { data, message, status } = await receivescores(postObj);

      if (status == 0) {
        getList();
        Toast('领取成功');
      } else {
        Toast(message);
      }
    };

    const sendLearn = () => {
      router.push({ path: '/Learning/sendGroup' });
    };

    // 全选
    const chooseAllChange = () => {
      state.chooseAll = !state.chooseAll;
      state.myComList.forEach(v => {
        v.result_item.checked = state.chooseAll;
      });
      console.log(state.myComList);
      asetCard();
    };

    // 重置已选
    const asetCard = () => {
      // 重置选择的藏品
      state.checkedArr = [];
      state.stopPoint = 0;
      state.myComList.forEach(i => {
        // 判断是否为终止派遣，需要过滤掉以及完成修炼的藏品
        if (
          i.result_item.checked &&
          i.result_item.status == 1 &&
          diffTime(i.result_item.end_time) > 0
        ) {
          state.checkedArr.push(i);
          state.stopPoint += i.result_item.points;
        }
        if (!i.result_item.checked) {
          state.chooseAll = false;
        }
      });
      computedPoint();
    };

    // 计算积分
    const computedPoint = () => {
      state.showPoint = 0;
      state.checkedArr.map(item => {
        state.configList.forEach(element => {
          if (
            element.token_id == item.token_id &&
            element.days == state.days &&
            element.type == 1
          ) {
            state.showPoint += element.points;
          }
        });
      });
    };

    // 选择二级分类所有藏品
    const chooseItem = index => {
      let item = state.myComList[index];
      state.myComList[index].result_item.checked = !item.result_item.checked;

      // 重置选择的藏品
      asetCard();
    };

    const showStopMeth = () => {
      if (state.checkedArr.length) {
        state.showstop = true;
      }
    };

    //停止修炼
    const stopClick = async () => {
      let id_list = state.checkedArr.map(item => {
        return item.result_item.user_collection_id;
      });
      const { data, message, status } = await comstopregular({ id_list });
      if (status == 0) {
        getList();
        state.checkedArr = [];
        state.showstop = false;
      } else {
        Toast(message);
      }
    };

    return {
      ...toRefs(state),
      coll_no_choose: require('@/imgs/icon/coll_no_choose.png'),
      coll_choose: require('@/imgs/icon/coll_choose.png'),
      diffTime,
      formatDuring,
      levelsChange,
      getPoint,
      sendLearn,
      chooseAllChange,
      chooseItem,
      showStopMeth,
      stopClick
    };
  }
};
</script>

<style lang="scss" scoped>
@import '../../styles/mixin.scss';

.appTop {
  padding-top: 70px !important;
}
.main {
  padding: 16px 0 66px 16px;
  box-sizing: border-box;
  .items {
    margin-bottom: 23px;
  }
  .title {
    font-size: 16px;
    //  margin-left: 8px;
    position: relative;
    margin: 11px 0;
    display: flex;
    justify-content: space-between;
    // align-items: center;
    .icon {
      font-size: 24px;
      margin-right: 16px;
      display: flex;
      align-items: center;
    }
    .name {
      margin-left: 10px;
    }
    span {
      font-size: 12px;
      margin-left: 3px;
      font-weight: 400;
    }
    &::before {
      content: '';
      width: 4px;
      height: 15px;
      background: #58dedd;
      position: absolute;
      left: 0;
      top: 4px;
    }
  }
  .card-box {
    display: flex;
    overflow: auto;
    .card-item {
      padding: 8px 7px 5px;
      background: #2b243f;
      border-radius: 4px 4px 4px 4px;
      border: 1px solid #494359;
      margin-right: 12px;
      position: relative;
      .tips {
        font-size: 10px;
        background: #000000;
        padding: 1px 2px;
        border-radius: 4px;
        position: absolute;
        z-index: 9;
        top: 0;
        left: 0;
      }
      .meng {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 4px 4px 4px 4px;
        background: rgba(0, 0, 0, 0.7);
        font-size: 16px;
        .point {
          margin: 40px 0 10px;

          font-weight: 500;
          color: #58dedd;
          span {
            font-size: 10px;
            color: #ffffff;
          }
        }

        .btn {
          width: 60px;
          height: 26px;
          display: flex;
          justify-content: center;
          align-items: center;
          background: linear-gradient(132deg, #8ee4c8 0%, #30daec 100%);
          border-radius: 24px 24px 24px 24px;
          color: #000000;
          font-weight: 500;
          font-size: 12px;
          margin: 0 auto;
        }
      }

      .comMeng {
        background: rgba(0, 0, 0, 0.4);
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: 58px;
          height: 22px;
        }
      }
      .card {
        font-size: 0px;
        position: relative;
        img {
          // min-width: 79px;
          width: 79px;
        }
        .levelIcon {
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          bottom: 5px;
          width: 43px !important;
          // height: auto;
        }
        .icon {
          position: absolute;
          font-size: 22px;
          top: 0;
          right: 0;
          z-index: 10;
        }
      }
      .num {
        font-size: 10px;
        color: rgba(255, 255, 255, 0.7);
        line-height: 16px;
        margin-top: 3px;
      }
    }
  }
}
.footer {
  position: fixed;
  bottom: 0;
  padding: 16px;
  background: #29233a;
  width: 100%;
  border-top: #494359 1px solid;
  box-sizing: border-box;
  z-index: 999;
  .type1 {
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    align-items: center;
    padding: 0 50px;
    .btn {
      padding: 6px 21px;
    }
  }
  .type3 {
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    align-items: center;
    .icon {
      font-size: 24px;
      display: flex;
      align-items: center;

      span {
        font-size: 12px;
        margin-left: 3px;
      }
    }
  }
  .type2 {
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    align-items: center;
    .bt-left {
      font-size: 12px;
      display: flex;
      align-items: center;
      .choose {
        display: flex;
        border-radius: 4px 4px 4px 4px;
        border: 1px solid #9991af;
        align-items: center;
        margin-right: 7px;
        .words {
          padding: 0 4px;
        }
        .icon {
          background: #9991af;
          font-size: 16px;
          display: flex;
          align-items: center;
          padding: 3px;
        }
      }
      .point {
        span {
          color: $primary;
        }
      }
    }
  }
  .btn {
    background: linear-gradient(132deg, #8ee4c8 0%, #30daec 100%);
    border-radius: 4px 4px 4px 4px;
    padding: 6px 7px;
    font-size: 16px;
    color: #1c172a;
    font-weight: 500;
  }
}

.dataNullBox {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;

  img {
    width: 150px;
    height: 110px;
  }

  .text {
    margin-top: 10px;
    font-size: 14px;
    font-weight: 400;
  }
  .icons {
    font-size: 14px;
    color: $primary;
    margin-top: 2px;
  }
}

.popup {
  width: 300px;
  background: #2f293f;
  border-radius: 8px 8px 8px 8px;
  text-align: left;
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;

  .content {
    padding: 16px;
    line-height: 26px;
    .title {
      font-size: 18px;
      font-weight: 500;
      text-align: center;
      margin-bottom: 20px;
    }
    .nei {
      // max-height: 350px;
      // overflow: auto;
      img {
        width: 100%;
      }
    }
  }

  .btn {
    div {
      text-align: center;
      line-height: 50px;
      font-size: 16px;
      border-top: 1px solid #494359;
    }
  }
}
</style>
<style lang="scss">
#invite-award {
  .vantPop {
    background: transparent !important;
  }
}
</style>